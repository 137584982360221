import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import FinishedTransportsChart from "../components/finished-transports-chart";
import FinishedByRobotChart from "../components/finished-by-robot-chart";

export const ChartsView = observer(() => {
  return (
    <BaseView cardBackgroundColor={false}>
      <FinishedTransportsChart />
      <FinishedByRobotChart />
    </BaseView>
  );
});
